import React from 'react';
import RenderLandingPage from './RenderLandingPage';

function LandingContainer() {
  return (
    <>
      <RenderLandingPage />
    </>
  );
}

export default LandingContainer;
